.image-tool__caption {
  border: 0;
  box-shadow: none;
  color: #6b7280;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  padding: 0;
}

.image-tool .cdx-button {
  border: 0;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  background-color: #f3f4f6;
  color: #3a65ea;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  border-radius: 12px;
  padding: 24px;
  justify-content: left !important;
}
