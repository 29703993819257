/* TODO: check how todo that on tailwind */
/* EditorJS only clean blocs that don't have text inside it, that's why I had to use the pseudo-element ::before */
/*

If we do something like

<div>
  {hasPlaceholder <p>{placeholder}</p>
  <div contentEditable />
</div>

The EditorJs won't recognize it as a empty block and won't delete when the user press Backspace.
Because the <p>{placeholder}</p> will still be considered text (That's my understanding of the behavior)
*/

.content-editable[data-placeholder]:empty::before {
  content: attr(data-placeholder);
  color: #9ca3af;
  font-weight: normal;
  opacity: 0;
}

/** Show placeholder at the first paragraph if Editor is empty */
.codex-editor--empty .ce-block:first-child .content-editable[data-placeholder]:empty::before {
  opacity: 1;
}

.codex-editor--toolbox-opened
  .ce-block:first-child
  .content-editable[data-placeholder]:empty::before,
.codex-editor--empty .ce-block:first-child .content-editable[data-placeholder]:empty:focus::before {
  opacity: 0;
}

.ce-popover {
  width: 250px;
}
